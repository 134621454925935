import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PreviewCompatibleImage from './PreviewCompatibleImage';

import { Box, Column, Columns, Section } from '../components/bulma';

export default function ContentSection({ items, box }) {
	let i = 0;
	return (
		<section>
			{items &&
				items.map(({ image, title, text, slug }) => (
					<Section>
						<div className="tile is-ancestor">
							{Boolean(++i % 2) && (
								<div className="tile is-6 is-vertical is-parent">
									<div className="tile is-child notification">
										<Section>
											<h3 className="title is-size-2 is-spaced">{title}</h3>
											<h4 className="subtitle is-4">{text}</h4>
											{slug && (
												<Link to={slug} className="button is-primary">
													Learn more
												</Link>
											)}
										</Section>
									</div>
								</div>
							)}
							<div className="tile is-vertical is-parent">
								<div className="tile is-child">
									<Section>
										<figure className="image is-256x256">
											{image && <PreviewCompatibleImage imageInfo={image} />}
										</figure>
									</Section>
								</div>
							</div>
							{Boolean(i % 2) || (
								<div className="tile is-6 is-vertical is-parent">
									<div className="tile is-child notification">
										<Section>
											<h3 className="title is-size-2 is-spaced">{title}</h3>
											<h4 className="subtitle is-4">{text}</h4>
											{slug && (
												<Link to={slug} className="button is-primary">
													Learn more
												</Link>
											)}
										</Section>
									</div>
								</div>
							)}
						</div>
					</Section>
				))}
		</section>
	);
}
