import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';

import PageHero from '../components/PageHero';
import Blurbs from '../components/Blurbs';
import ContentSection from '../components/ContentSection';
import FinalSection from '../components/FinalSection';
// import BlogSection from '../components/BlogSection';
import CTA from '../components/CTA';

export default function PlatformPage({ data }) {
	const { markdownRemark: page } = data;
	const { image, heading, subheading, sections, blurbs, final } = page.frontmatter;

	return (
		<Layout>
			<PlatformPageTemplate
				image={image}
				heading={heading}
				subheading={subheading}
				blurbs={blurbs}
				sections={sections}
				final={final}
			/>
		</Layout>
	);
}

export const PlatformPageTemplate = ({ image, heading, subheading, sections, blurbs, final }) => (
	<div>
		<PageHero title={heading} subtitle={subheading} image={image} signup />
		{/* <Blurbs box items={blurbs} /> */}
		{/* <ContentSection items={sections} box /> */}
		{/* <FinalSection content={final} /> */}
		{/* <BlogSection /> */}
		<CTA />
	</div>
);

export const pageQuery = graphql`
	query PlatformPageByID($id: String!) {
		markdownRemark(id: { eq: $id }) {
			frontmatter {
				title
				heading
				subheading
				image {
					childImageSharp {
						fluid(maxWidth: 2048, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				blurbs {
					image {
						childImageSharp {
							fluid(maxWidth: 240, quality: 64) {
								...GatsbyImageSharpFluid_withWebp
							}
						}
					}
					title
					text
				}
				sections {
					title
					text
					color
					side
					image {
						childImageSharp {
							fluid(maxWidth: 240, quality: 64) {
								...GatsbyImageSharpFluid_withWebp
							}
						}
					}
				}
				final {
					title
					text
					Image {
						alt
						image {
							childImageSharp {
								fluid(maxWidth: 400, quality: 64) {
									...GatsbyImageSharpFluid_withWebp
								}
							}
						}
					}
				}
			}
		}
	}
`;
